import { Link, graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import parse from 'html-react-parser'
import React, { useEffect } from 'react'

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import '../css/@wordpress/block-library/build-style/style.css'
import '../css/@wordpress/block-library/build-style/theme.css'

import { Col, Row } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Layout from '../components/layout'
import MainCTA from '../components/sections/main-cta'
import Seo from '../components/seo'


const AuthorList = ({ authors }) => {
  const renderAuthor = (author) => {
    return author.teamMembers.blogDisplayName ? 
      author.teamMembers.blogDisplayName : 
      `${author.title}${author.teamMembers.jobTitle ? `, ${author.teamMembers.jobTitle}` : ''}`;
  };

  const renderedAuthors = authors.map(renderAuthor);

  if (renderedAuthors.length > 2) {
    const lastAuthor = renderedAuthors.pop();
    return <Link to="/team">{renderedAuthors.join(', ')}, and {lastAuthor}</Link>;
  } else {
    return <Link to="/team">{renderedAuthors.join(' and ')}</Link>;
  }
}


const BlogPostTemplate = ({ data: { post } }) => {
  console.log({data: { post }})
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || '',
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

    gsap.to(window, { duration: 0.35, scrollTo: 0 })

    const mm = gsap.matchMedia()
    mm.add('(min-width: 768px)', () => {
      const pinTl = gsap.timeline({
        scrollTrigger: {
          trigger: '#singleBlogContent',
          pin: '#moColumn',
          start: 'top 80px',
          // endTrigger: "#singleRecipe",
          end: 'bottom bottom',
          pinSpacing: false,
        },
      })
    })

    return () => {
      // We kill all scroll triggers so the next pages can recreate them.
      const triggers = ScrollTrigger.getAll()
      if (triggers) {
        triggers.forEach((trigger) => {
          trigger.kill()
        })
      }
    }
  }, [])

  return (
    
    <Layout>
      <Seo title={`${post.title} Blog`} description={post.excerpt} />
      <header className="header-wave-with-subtitle bg-warning" id="singleBlogHero">
        <div className="header-wave-top py-4 d-flex align-items-center justify-content-center">
          {
            post.AnimatedFeatureImage?.animatedFeatureImage?.localFile?.publicURL
              && (
              <div className="gatsby-image-wrapper gatsby-image-wrapper-constrained hero-image-container">
                <img src={post.AnimatedFeatureImage?.animatedFeatureImage?.localFile?.publicURL} alt={post.title} className="hero-image" />
              </div>
              )
            }
          { !post.AnimatedFeatureImage?.animatedFeatureImage?.localFile?.publicURL && (
          <GatsbyImage
            loading="eager"
            image={featuredImage.data}
            alt={featuredImage.alt}
            placeholder="blurred"
            layout="fullWidth"
            breakpoints={[575, 767, 991, 1199, 1399, 1920, 2999]}
            className="hero-image-container"
            imgClassName="hero-image"
          />
          ) }
          <div className="header-overlay" />
        </div>
      </header>
      <section id="singleBlogContent" className="py-2 py-md-3 py-lg-4">
        <Container>
          <Row className="align-items-start">
            <Col md="4" id="moColumn">
              <StaticImage
                as="div"
                className="mo-blog"
                src="../images/mo/meati-happy-yellow.png"
                quality={95}
                formats={['AUTO', 'WEBP']}
                alt="Meati character"
                placeholder="blurred"
                layout="fullWidth"
              />
            </Col>
            <Col md="8">
              <div className="mb-2 eyebrow text-muted">
                <span className="me-1">Posted in:
                <Link to="/blog" className="text-uppercase ms-14">Meati™ Blog</Link></span>
                <span>By:{" "}
                  {post.blogPostAuthor.author?.length ?
                    <AuthorList authors={post.blogPostAuthor.author} />
                   : 'Meati'}
                </span>
              </div>
              <h1 className="font-swish mb-12 mb-md-1 fw-bold">{post.title}</h1>
              <h3 className="mb-1 mb-md-2 fst-italic subtitle" dangerouslySetInnerHTML={{ __html: post.excerpt }} />
              {parse(post.content)}
            </Col>
          </Row>
        </Container>
      </section>
      <MainCTA />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      blogPostAuthor {
      author {
        ... on WpTeamMember {
          title
          teamMembers {
            jobTitle
            blogDisplayName
          }
        }
      }
    }
    AnimatedFeatureImage {
      animatedFeatureImage {
        localFile {
          publicURL
          url
        }
      }
    }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                width: 1920
                placeholder: BLURRED
                formats: NO_CHANGE
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
